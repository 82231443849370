// import { Button } from "bootstrap";
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import { useContext } from "react";
import { Table, Pagination } from "rsuite";

import { useGetGeneratorListQuery } from "../../../features/generator/generatorDataLogApi";

import { useGetOilConsuptionReportMutation } from "../../../features/generator/generatorReportApi";

import { useDailyEnergyConsumptionMutation } from "../../../features/acrel/dateWiseEnergyConsumption/dateWiseEnergyConsumptionApi";

import { useGetAcrelMeterListQuery } from "../../../features/acrel/realtimeDashboard/acrelRealtimeDashboardApi";

import useTitle from "../../../hooks/useTitle";
import BarChart from "./BarChart";

const { Column, HeaderCell, Cell } = Table;

function DateEnergyConsumptionForDashboard() {
  useTitle("Energy Consumption");
  const theme = useContext(ThemeContext);
  const [formData, setFormData] = useState({});
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [dateWiseData, setDateWiseData] = useState([]);
  const [totalData, setTotalData] = useState({});

  const [tableLoading, setTableLoading] = useState(true);

  //   const [getOilConsuptionReport, { isLoading: dateWiseLoading }] =
  //     useGetOilConsuptionReportMutation();

  const [dailyEnergyConsumption, { isLoading: dateWiseLoading }] =
    useDailyEnergyConsumptionMutation();

  const { data: meterList, isLoading } = useGetAcrelMeterListQuery();

  const { data: generatorListResponse, isLoading: generatorListLoading } =
    useGetGeneratorListQuery();

  useEffect(() => {
    dailyEnergyConsumption()
      .unwrap()
      .then((payload) => {
        setDateWiseData(payload.data);
        setTotalData({
          total_energy_consumption: payload?.footer?.total_energy_consumption,
          total_carbon_emission: payload?.footer?.total_carbon_emission,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    if (!dateWiseLoading) {
      setTableLoading(false);
    }
  }, [dateWiseLoading]);

  let defaultData = [];
  let data = [];

  if (!dateWiseLoading) {
    defaultData = dateWiseData?.map((alertInfo) => {
      const data = new Date(alertInfo.updated_at);
      const formattedDate = data.toLocaleString();
      return {
        ...alertInfo,
        updated_at: formattedDate,
      };
    });

    // data = defaultData?.filter((v, i) => {
    //   const start = limit * (page - 1);
    //   const end = start + limit;
    //   return i >= start && i < end;
    // });

    data = [...defaultData];
  }

  const dataChangeHandler = (e) => {
    setFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const searchSumbitHandler = (e) => {
    e.preventDefault();
    setPage(1);
    console.log(formData);
    dailyEnergyConsumption(formData)
      .unwrap()
      .then((payload) => {
        setDateWiseData(payload.data);
        setTotalData({
          total_energy_consumption: payload?.footer?.total_energy_consumption,
          total_carbon_emission: payload?.footer?.total_carbon_emission,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const totalRow = {
    id: "Total",
    energy_consumption: `Total Consumption : ${totalData?.total_energy_consumption}`,
    carbon_emission: `Total Carbon Emission : ${totalData?.total_carbon_emission}`,
  };
  const columns = [
    // {
    //   name: "id",
    //   title: "Id",
    // },
    {
      name: "device_code",
      title: "Device Code",
    },
    {
      name: "meter_name",
      title: "Meter Name",
    },
    {
      name: "energy_consumption",
      title: "Energy Consumption(KWh)",
    },
    {
      name: "carbon_emission",
      title: "Carbon Emission(Kg)",
    },
    {
      name: "date",
      title: "Date",
    },
  ];

  //   const downloadCSVHandler = (e) => {
  //     e.preventDefault();
  //     const baseUrl = process.env.REACT_APP_API_URL + "/apiV2";
  //     const exportURL = `${baseUrl}/generator/oil-consumption-pdf`;
  //     if (formData?.startdate && formData?.combined_id) {
  //       const exportUrlWithDate =
  //         exportURL +
  //         `?startdate=${formData?.startdate}&enddate=${formData?.enddate}&combined_id=${formData?.combined_id}`;
  //       window.location.replace(exportUrlWithDate);
  //     } else if (formData?.startdate) {
  //       const exportUrlWithDate =
  //         exportURL +
  //         `?startdate=${formData?.startdate}&enddate=${formData?.enddate}`;
  //       window.location.replace(exportUrlWithDate);
  //     } else {
  //       window.location.replace(exportURL);
  //     }
  //   };

  return (
    <div className={` ${theme} `}>
      {/* <div
        className="body-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Energy Consumption Report
      </div> */}
      {/* <div
        className="col-md-10"
        style={{ marginLeft: "-10px", marginBottom: "1rem" }}
      >
        <form onSubmit={(e) => searchSumbitHandler(e)}>
          <div className="row">
            <div className="form-group col-md-2">
              <label className="body-text">Start Date </label>
              <input
                type="date"
                className="form-control"
                name="startdate"
                onChange={dataChangeHandler}
                required
              />
            </div>
            <div class="form-group col-md-2">
              <label className="body-text">End Date</label>
              <input
                type="date"
                class="form-control"
                name="enddate"
                onChange={dataChangeHandler}
                required
              />
            </div>
            <div className="col-md-2 date-search-submit-btn">
              <select
                className="form-select"
                aria-label="Default select example"
                name="combined_id"
                onChange={dataChangeHandler}
                required
              >
                <option value="" selected>
                  Select Meter
                </option>
                {meterList?.data?.map((meterInfo) => {
                  return (
                    <option value={meterInfo?.combined_id}>
                      {meterInfo?.meter_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-2 date-search-submit-btn">
              <button type="submit" class="btn btn-primary col-md-12">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div> */}
      <div
        className="  body-text"
        style={{ padding: "5px", paddingTop: "5px" }}
      >
        <div className="card-portion card-color body-text">
          <div className="title-portion">
            <div className="title-color"></div>
            <div className="title-text">Energy Consumption Report</div>
          </div>
          <div className="card-body">
            <div className="theme-background body-text">
              <div className="table-responsive theme-background body-text">
                <Table height={500} data={[...data]} virtualized>
                  {columns.map((column) => (
                    <Column
                      key={column.name}
                      width={100}
                      // resizable
                      align="center"
                      flexGrow={1}
                      fullText
                    >
                      <HeaderCell>{column.title}</HeaderCell>
                      <Cell dataKey={column.name} />
                    </Column>
                  ))}
                </Table>
                {/* <div className="total-row" style={{ background: "transparent" }}>
            {columns.map((column) => (
              <div
                key={column.name}
                className="total-cell theme-background theme-text"
                style={{ width: "200px", border: "none", fontWeight: "bold" }}
              >
                {column.name === "name" ? "Total" : totalRow[column.name]}
              </div>
            ))}
          </div> */}
                {/* <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData?.length}
              limitOptions={[10, 30, 50, 100, 200]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DateEnergyConsumptionForDashboard;
