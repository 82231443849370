import React, { useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import ToggledTabs from "./ToggledTabs";
import { useGetMeterDetailsQuery } from "../../../../features/acrel/realtimeDashboard/acrelRealtimeDashboardApi";

function DetailsPage({ meterStatus, onClick, meterId }) {
  console.log("meter status : " + meterStatus);
  const {
    data: detailsCardData,
    isLoading,
    isFetching,
  } = useGetMeterDetailsQuery(meterId);

  let cardElement;
  if (!isFetching) {
    if (detailsCardData) {
      let totalCard = Object.keys(detailsCardData);
      cardElement = totalCard.map((item, i) => {
        if (i > 2) {
          const cardValues = detailsCardData[item];
          const cardValueKey = Object.keys(detailsCardData[item]);

          return (
            <div key={item} className="col-md-3 meter-card">
              <div className="card-section card-secondary">
                <div className="card-header-section">{item}</div>
                <div>
                  {cardValueKey?.map((valueItem) => (
                    <div style={{ paddingTop: "10px" }}>
                      {valueItem}：
                      <span
                        className="body-text"
                        style={{ fontWeight: "bold" }}
                      >
                        {cardValues[valueItem]?.value}{" "}
                        {cardValues[valueItem]?.unit}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        }
      });
    }
  }

  return (
    <div>
      <div onClick={onClick} style={{ cursor: "pointer" }}>
        <IoIosArrowRoundBack size={25} />
        Back
      </div>

      <div className="my-2">
        {meterStatus === "1" ? (
          <span className="status-text">Online</span>
        ) : (
          <span className="status-text" style={{ backgroundColor: "#c7341a" }}>
            Offline
          </span>
        )}

        {/* <span className="status-text">Normal</span> */}
      </div>
      <div className="section-separator"></div>
      <div className="header-texts my-3">
        <div>Device ID : {meterId}</div>
        {/* <div>Data update time: 2024-03-25 15:30:00</div> */}
      </div>
      <div className="row">
        {cardElement}
        <ToggledTabs meterId={meterId} />
      </div>
    </div>
  );
}

export default DetailsPage;
