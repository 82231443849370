import { apiSlice } from "../api/apiSlice";

export const energyMeterUpdatedDashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    energyDashboardDataFetch: builder.query({
      query: () => "/apiV2/energy-meter-dashboard",
    }),
    energyDashboardDetailsDataFetch: builder.query({
      query: (id) => `/apiV2/energy-meter-dashboard-details?meter_id=${id}`,
    }),
    energyDetailsGaugeDataFetch: builder.query({
      query: (id) => `/apiV2/energy-meter-dashboard-gauge?meter_id=${id}`,
    }),
    latestEnergyConsumption: builder.query({
      query: (id) => `/apiV2/energy-meter-latest-consumption?meter_id=${id}`,
    }),
    todayEnergyConsumption: builder.query({
      query: (id) => `/apiV2/energy-meter-today-consumption?meter_id=${id}`,
    }),
    dashboardPieChart: builder.query({
      query: () => `/apiV2/energy-meter-dashboard-pie-chart`,
    }),
  }),
});

export const {
  useEnergyDashboardDataFetchQuery,
  useLazyEnergyDashboardDataFetchQuery,
  useEnergyDashboardDetailsDataFetchQuery,
  useEnergyDetailsGaugeDataFetchQuery,
  useLatestEnergyConsumptionQuery,
  useTodayEnergyConsumptionQuery,
  useDashboardPieChartQuery,
} = energyMeterUpdatedDashboardApi;
