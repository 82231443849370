import CompB from "../components/CompB";
import CompC from "../components/CompC";
import BaseLayout from "../layout/BaseLayout";
import CompD from "../components/CompD";
import { Outlet } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import { FaBeer, FaDoorClosed, FaBell, FaCity, FaFish } from "react-icons/fa";
import { RiDashboardFill, RiSensorFill, RiDeviceFill } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";
import { AiFillControl } from "react-icons/ai";
import { AiFillSetting } from "react-icons/ai";

import { MdAssignment } from "react-icons/md";
import { BsFillBarChartFill } from "react-icons/bs";
import { AiTwotoneNotification } from "react-icons/ai";
import { GiWaterTank } from "react-icons/gi";
import { CgSmartHomeBoiler } from "react-icons/cg";
import { FaMoneyBill1Wave } from "react-icons/fa6";
import { TbCalendarDollar } from "react-icons/tb";
import { FaChargingStation } from "react-icons/fa";

import TestComponent from "../components/TestComponent";
import AlertSettings from "../components/alert/alertSettings";
import CreateSensor from "../components/sensor/createSensor";
// import SensorMap from "../components/sensor/sensorMap";
import CreatePremises from "../components/premises/createPremises";
import ListPremises from "../components/premises/listPremises";
import DeviceConfiguration from "../components/device/deviceConfiguration";
import RoomConfiguration from "../components/premises/roomConfiguration";
// import Building from "../components/premises/building";
import { ImDatabase } from "react-icons/im";
import DataLogMain from "../components/dataLog/DataLogMain";
import RoomDetailsMain from "../components/dashboard/roomDetails/RoomDetailsMain";
import BasicTableComponent from "../components/BasicTableComponent";
import Login from "../components/auth/Login";
import PrivateRoute from "../components/PrivateRoute";
import PublicRoute from "../components/PublicRoute";
import Logout from "../components/auth/Logout";
import Premises from "../components/premises/premisesCreate/Premises";
import EnergyDashboard from "../components/energyMeter/EnergyDashboard";
import { ImPower } from "react-icons/im";
import EnergyDetailsMain from "../components/energyMeter/details/EnergyDetailsMain";
import Rooms from "../components/rooms/Rooms";
import Building from "../components/building/Building";
import SlaveAssign from "../components/slaveAssign/SlaveAssign";
import DeviceConfig from "../components/device-config/DeviceConfig";
import SensorMap from "../components/sensor-map/SensorMap";
import MotorControl from "../components/motor-control/MotorControl";
import NotificationMain from "../components/notification/NotificationMain";
import Tank from "../components/Tank/Tank";
import Fish from "../components/fish/Fish";
import FishTankAssign from "../components/fish/FishTankAssign";
import FishDesease from "../components/fish/FishDesease";
import FishFeed from "../components/fish/FishFeed";
import FishDailyFeed from "../components/fish/FishDailyFeed";
import FishLog from "../components/fish/FishLog";
import DailyFishLogMain from "../components/fish/fishLog/dailyFishLog/DailyFishLogMain";
import MonthlyFishLogMain from "../components/fish/fishLog/monthlyFishLog/MonthlyFishLogMain";
import GrossFishLogMain from "../components/fish/fishLog/grossFishLog/GrossFishLogMain";
import GeneratorDashboard from "../components/generator/dashboard/GeneratorDashboard";
import DashboardDetailsMain from "../components/generator/dashboardDetails/DashboardDetailsMain";
import GeneratorDataLogMain from "../components/generator/dataLog/GeneratorDataLogMain";
import InformationUpdate from "../components/generator/informationUpdate/InformationUpdate";
import DaywiseConsumption from "../components/generator/daywiseOilConsumptionReport/DaywiseConsumption";
import DataTableWithTotalCount from "../components/generator/daywiseOilConsumptionReport/DataTableWithTotalCount";
import OilConsumptionReport from "../components/generator/oilConsumptionReport/OilConsumptionReport";
import ChartReportsMain from "../components/generator/chartReports/ChartReportsMain";
import AnomalyReport from "../components/generator/anomalyReport/AnomalyReport";
import EnergyMeterDashboardUpdated from "../components/energyMeterUpdated/dashboard/EnergyMeterDashboardUpdated";
import EnergyMeterDashboardDetails from "../components/energyMeterUpdated/dashboard_details/EnergyMeterDashboardDetails";
import EnergyMeterDataLogMain from "../components/energyMeterUpdated/dataLog/EnergyMeterDataLogMain";
import EnergyConsumptionGraphMain from "../components/energyMeterUpdated/energy_consumption_graph/EnergyConsumptionGraphMain";
import MaintenanceReport from "../components/generator/maintenanceReport/MaintenanceReport";
import Billing from "../components/energyMeterUpdated/billing/Billing";
import ColumbiaDashboard from "../components/generator/columbiaDashboard/ColumbiaDashboard";
import AmanDashboard from "../components/aman-dashboard/dashboard/AmanDashboard";
import AdjustedReport from "../components/generator/adjustedReport/AdjustedReport";
import CompanyCreate from "../components/aman-dashboard/companyCreate/CompanyCreate";
import CompanyAssign from "../components/aman-dashboard/companyAssign/CompanyAssign";
import ConsumptionReport from "../components/generator/consumptionReport/ConsumptionReport";
import BoilerDashboard from "../components/boiler/dashboard/BoilerDashboard";
import BoilerDataLogMain from "../components/boiler/dataLog/BoilerDataLogMain";
import BoilerCompanyDashboard from "../components/aman-dashboard/boilerCompanyDashboard/BoilerCompanyDashboard";
import GeneratorCompanyDashboard from "../components/aman-dashboard/generatorCompanyDashboard/GeneratorCompanyDashboard";
import EnergyCompanyDashboard from "../components/aman-dashboard/energyCompanyDashboard/EnergyCompanyDashboard";
import OilInReport from "../components/generator/oilInReport/OilInReport";
import BoilerOperatingReport from "../components/boiler/boilerOperatingReport/BoilerOperatingReport";
import MainDashboard from "../components/acrel/main-dashboard/MainDashboard";
import RealTimeMonitor from "../components/acrel/power-monitoring/realTimeMonitoring/RealTimeMonitor";
import ElectricParameterReport from "../components/acrel/power-monitoring/electricParameterReport/ElectricParameterReport";
import ElectricReport from "../components/acrel/power-monitoring/electricReport/ElectricReport";
import BillCalculation from "../components/acrel/bill/BillCalculation";
import BillReport from "../components/acrel/bill/BillReport";
import DateEnergyConsumption from "../components/acrel/date-wise-energy-consumption/DateEnergyConsumption";

//first route shouldnot have children might create issue on sidebar

export const routes = [
  {
    title: "Default",
    path: "/",
    icon: <RiDashboardFill />,
    visibility: false,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    title: "Login",
    path: "/login",
    icon: <RiDashboardFill />,
    visibility: false,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    title: "Main Dashboard",
    path: "/main-dashboard",
    icon: <RiDashboardFill />,
    visibility: true,
    element: (
      <PrivateRoute>
        <MainDashboard />
      </PrivateRoute>
    ),
  },

  {
    title: "Power Monitoring",
    path: "/power-monitoring",
    icon: <ImPower />,
    visibility: false,
    element: (
      <PrivateRoute>
        <RealTimeMonitor />
      </PrivateRoute>
    ),
  },
  {
    title: "Power Monitoring",
    path: "/power-monitoring",
    icon: <ImPower />,
    visibility: true,
    children: [
      {
        title: "Real-time Monitor",
        path: "real-time-monitor",
        element: (
          <PrivateRoute>
            <RealTimeMonitor />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      {
        title: "Electric Parameter Report",
        path: "electric-parameter-report",
        element: (
          <PrivateRoute>
            <ElectricParameterReport />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      {
        title: "Electric Report",
        path: "electric-report",
        element: (
          <PrivateRoute>
            <ElectricReport />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
    ],
  },
  {
    title: "Energy Consumption",
    path: "/energy-consumption",
    icon: <FaChargingStation />,
    visibility: true,
    element: (
      <PrivateRoute>
        <DateEnergyConsumption />
      </PrivateRoute>
    ),
  },
  {
    title: "Bill Calculation",
    path: "/bill-calculation",
    icon: <FaMoneyBill1Wave />,
    visibility: true,
    element: (
      <PrivateRoute>
        <BillCalculation />
      </PrivateRoute>
    ),
  },
  {
    title: "Bill Report ",
    path: "/bill-report",
    icon: <TbCalendarDollar />,
    visibility: true,
    element: (
      <PrivateRoute>
        <BillReport />
      </PrivateRoute>
    ),
  },
  {
    title: "logout",
    path: "/logout",
    icon: <FiLogOut />,
    visibility: true,
    element: <Logout />,
  },
  {
    path: "*",
    visibility: false,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  }, //to redirect unknown routes during login
];
