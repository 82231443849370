import React, { useState } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";

import "./drawer.css";
import { Drawer, ButtonToolbar, Button, Placeholder } from "rsuite";

function DrawerComponent() {
  const [open, setOpen] = React.useState(false);
  const background = "red";
  return (
    <>
      {/* <ButtonToolbar>
        <Button onClick={() => setOpen(true)}>Open</Button>
      </ButtonToolbar> */}
      <div className="theme-text ">
        <IoMdNotificationsOutline size="25px" onClick={() => setOpen(true)} />
      </div>

      <Drawer size={"xs"} open={open} onClose={() => setOpen(false)} style={{}}>
        <Drawer.Header
        // style={{
        //   backgroundColor: "red",
        // }}
        >
          <div>
            {" "}
            <Drawer.Title
              style={{
                border: "10px",
                padding: "10px",
                backgroundColor: background,
                color:"blue",
              }}
            >
              Latest Notification
            </Drawer.Title>
          </div>
        </Drawer.Header>
        <Drawer.Body
          style={{
            border: "10px",
            padding: "10px",
            backgroundColor: background,
          }}
        >
          <div className="body-container">
            <div className="notification-item theme-text">item 1</div>
            <div className="notification-item"></div>
            <div className="notification-item"></div>
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
}

export default DrawerComponent;
