import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

const BarChart = ({ chartData, chartType }) => {
  let barChartData = chartData?.map((data) => {
    return chartType == "energy_consumption"
      ? data?.energy_consumption
      : data?.carbon_emission;
  });
  let chartLabel = chartData?.map((data) => {
    return data?.date;
  });
  // Sample data for the bar chart
  const data = {
    labels: chartLabel,
    datasets: [
      {
        label:
          chartType == "energy_consumption"
            ? "Energy Consumption"
            : "Carbon Emission",
        data: barChartData,
        backgroundColor: [
          "#33C0B9",
          "#33C0B9",
          "#33C0B9",
          "#33C0B9",
          "#33C0B9",
          "#33C0B9",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false, // This allows the chart to resize freely
  };
  return (
    <div style={{ height: "285px" }}>
      <Bar data={data} height={205} options={options} />
    </div>
  );
};

export default BarChart;
