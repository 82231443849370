import React from "react";
import { MdElectricMeter } from "react-icons/md";
import electricMeterIcon from "./assets/electricMeter.png";

import { useGetAcrelMeterListQuery } from "../../../../features/acrel/realtimeDashboard/acrelRealtimeDashboardApi";

function RightSectionMain({ setMeterStatus, onClick, setMeterId }) {
  const { data: meterList, isLoading } = useGetAcrelMeterListQuery();
  const meterIdSet = (meterId) => {
    setMeterId(meterId);
  };
  return (
    <div>
      {/* <div className="row">
        <button className="col-md-1 btn btn-success mx-1">normal</button>
        <button className="col-md-1 btn btn-secondary mx-1">offline</button>
        <button className="col-md-1 btn btn-warning mx-1">fault</button>
        <button className="col-md-1 btn btn-danger mx-1">alarm</button>
        <select id="dropdown" className="col-md-1 mx-1">
          <option value="">Node Type</option>
          <option value="option1">Building</option>
          <option value="option2">User</option>
          <option value="option3">Department</option>
          <option value="option4">Air Condition</option>
          <option value="option5">Lighting</option>
        </select>
        <select className="col-md-1 mx-1">
          <option value="">Node </option>
        </select>
        <select className="col-md-1 mx-1">
          <option value="">Energy Type </option>
        </select>
        <input type="text" className="col-md-2 mx-1" placeholder="Geteway Id" />
        <button className="col-md-1 btn btn-success mx-1">Array Mode</button>
        <button className="col-md-1 btn btn-info mx-1">Table Mode</button>
      </div> */}
      <div className="card-container row">
        {" "}
        {meterList?.data?.map((item) => {
          return (
            <div className="col-md-6 col-sm-12 col-xl-3 col-lg-4 meter-card">
              <div
                className="card-section card-secondary"
                onClick={() => {
                  onClick();
                  setMeterStatus(item?.status);
                  setMeterId(item?.combined_id);
                }}
              >
                <div className="card-top-section">
                  <div>{item?.meter_name}</div>

                  {item.status === "1" ? (
                    <div className="status-text">Online</div>
                  ) : (
                    <div
                      className="status-text"
                      style={{ backgroundColor: "#c7341a" }}
                    >
                      Offline
                    </div>
                  )}
                </div>
                <div className="card-bottom-section">
                  <div className="card-bottom-left">
                    {/* <MdElectricMeter size={60} color={"#4BA5A0"} /> */}
                    <img
                      src={electricMeterIcon}
                      alt="electricMeterIcon"
                      style={{ height: "55px" }}
                    />
                  </div>
                  <div className="card-bottom-right">
                    <div>Gateway Id：{item?.device_code}</div>
                    <div>Meter Address：{item?.combined_id}</div>
                    <div>Meter Type：{item?.meter_name}</div>
                  </div>
                </div>
                <div className="card-bottom-text-section">
                  <div
                    className="card-bottom-text"
                    onClick={() => {
                      onClick();
                      setMeterStatus(item?.status);
                      setMeterId(item?.combined_id);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Details
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RightSectionMain;
